<template>
  <v-select
    v-model="statusSelected"
    :items="status"
    item-value="value"
    item-text="text"
    attach
    chips
    label="Status do Atendimento"
    multiple
  ></v-select>
</template>

<script>
export default {
  name: 'PedidoStatusFilter',

  data: () => ({
    status: [
      {
        text: 'Recusado',
        value: 'recusado'
      },
      {
        text: 'Atendido',
        value: 'atendido'
      },
      {
        text: 'Pendente',
        value: 'pendente'
      },
      {
        text: 'Saiu para Entrega',
        value: 'saiu-para-entrega'
      },
      {
        text: 'Entregue',
        value: 'entregue'
      },
      {
        text: 'Cancelado',
        value: 'cancelado'
      },
      {
        text: 'Erro no Pagamento',
        value: 'erro-pagamento'
      },
      {
        text: 'Fechado',
        value: 'fechado'
      }
    ],
    statusSelected: ['atendido', 'saiu-para-entrega', 'entregue']
  }),

  watch: {
    statusSelected() {
      this.eventStatusPedidoChange()
    }
  },

  methods: {
    eventStatusPedidoChange() {
      const eventPayload = []

      for (const item of this.statusSelected) {
        eventPayload.push(item)
      }

      this.$emit('pedido-status-change', eventPayload)
    }
  },

  mounted() {
    this.eventStatusPedidoChange()
  }
}
</script>
