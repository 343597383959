var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1000"},on:{"input":function($event){_vm.open = $event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"10px"},attrs:{"color":"success","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Conciliação Bancária ")])]}}])},[_c('v-card',[_c('v-card-title',[_vm._v("Conciliação Bancária")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Data inicial","prepend-icon":"mdi-event","readonly":""},model:{value:(_vm.getterInitialDateLocal),callback:function ($$v) {_vm.getterInitialDateLocal=$$v},expression:"getterInitialDateLocal"}},on))]}}]),model:{value:(_vm.initialDateMenu),callback:function ($$v) {_vm.initialDateMenu=$$v},expression:"initialDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":_vm.selectInitialDate}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Data final","prepend-icon":"mdi-event","readonly":""},model:{value:(_vm.getterFinalDateLocal),callback:function ($$v) {_vm.getterFinalDateLocal=$$v},expression:"getterFinalDateLocal"}},on))]}}]),model:{value:(_vm.finalDateMenu),callback:function ($$v) {_vm.finalDateMenu=$$v},expression:"finalDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":_vm.selectFinalDate}})],1)],1),_c('v-col',[_c('prestadores-servico-filter',{on:{"prestadores-servico-change":function($event){_vm.estabelecimentos = $event}}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"success","dark":""},on:{"click":_vm.createRelatorioRecebiveis}},[_vm._v(" Criar Relatório ")])],1)],1),_c('div',{staticClass:"div-table"},[_c('h3',[_vm._v("Relatórios")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Data início")]),_c('th',{staticClass:"text-center"},[_vm._v("Data final")]),_c('th',{staticClass:"text-center"},[_vm._v("Status")]),_c('th',{staticClass:"text-center"},[_vm._v("Link")])])]),_c('tbody',_vm._l((_vm.getterRelatoriosRecebiveis),function(relatorio){return _c('tr',{key:relatorio._id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(relatorio.data_inicio))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(relatorio.data_final))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.statusRelatorio(relatorio.status)))]),_c('td',{staticClass:"text-center"},[(relatorio.status === 'succeeded')?_c('a',{attrs:{"href":_vm.link(relatorio)}},[_vm._v(" "+_vm._s(relatorio.nome_arquivo)+" ")]):_vm._e()])])}),0)]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }