import { GETTERS } from './types'
import { toLocalDate } from '../utils'

export default {
  [GETTERS.ESTABELECIMENTOS]: state => state.estabelecimentos,

  [GETTERS.PEDIDOS]: state => state.pedidos,

  [GETTERS.PROPRIETARIO]: state => state.proprietario,

  [GETTERS.ESTABELECIMENTO_ID]: state => state.estabelecimento_id,

  [GETTERS.RELATORIOS_RECEBIVEIS]: state => state.relatorios_recebiveis,

  [GETTERS.PRODUTOS_VENDIDOS]: state => state.produtos_vendidos,

  [GETTERS.COMPLEMENTOS_VENDIDOS]: state => state.complementos_vendidos,

  [GETTERS.CONSUMO_CLIENTES]: state => {
    const consumos = []

    for (const consumo of state.consumo_clientes) {
      let ticket_medio = 0

      const { quantidade_pedidos, consumo_total } = consumo

      if (quantidade_pedidos !== 0) {
        ticket_medio = consumo_total / quantidade_pedidos
      }

      consumos.push({ ...consumo, ticket_medio })
    }

    return consumos
  },

  [GETTERS.INITIAL_DATE]: state => state.initial_date,

  [GETTERS.FINAL_DATE]: state => state.final_date,

  [GETTERS.INITIAL_DATE_LOCAL]: state => toLocalDate(state.initial_date),

  [GETTERS.FINAL_DATE_LOCAL]: state => toLocalDate(state.final_date),
}
