<template>
  <v-dialog width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Exibir resumo
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Resumo Financeiro
        <v-spacer></v-spacer>
        <v-menu left offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-subheader>Relatório</v-subheader>
            <v-list-item v-if="getterPedidos.length === 0" class="red--text">
              Nenhum atendimento encontrado.
            </v-list-item>
            <template v-else>
              <v-list-item v-for="item in prestadores_servico" :key="item.id" @click="showExportTypeModal(item)">
                <v-list-item-title>{{ item.nome }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-dialog max-width="360px" v-model="exportTypeModal">
          <v-card>
            <v-card-title>Selecione o tipo do Relatório</v-card-title>
            <v-card-text>
              <p>
                Relatório Completo: Exibe todos os atendimentos realizados no período selecionado.
              </p>
              <p>
                Livro Caixa: Exibe os atendimentos realizados no período selecionado, porém,
                no formato utilizado pela Receita Federal, para facilitar a declaração de imposto de renda.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn color="info" @click="saveResultXLSX(supplier)">Completo</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="info" @click="saveResultCSV(supplier)">Livro caixa</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>Atendimentos: <strong>{{ summary.atendimentos }}</strong></v-col>
          <v-col>Receita: <strong>{{ formatCurrency(summary.total) }}</strong></v-col>
          <v-col>Ticket médio: <strong>{{ formatCurrency(summary.ticket_medio) }}</strong></v-col>
        </v-row>
        <v-row>
          <v-col>Custos: <strong>{{ formatCurrency(summary.custos) }}</strong></v-col>
          <v-col>Impostos: <strong>{{ formatCurrency(summary.impostos) }}</strong></v-col>
          <v-col>Lucro Bruto: <strong>{{ formatCurrency(summary.markup) }}</strong></v-col>
        </v-row>
        <v-row v-if="getterProprietario">
          <v-col>Receita do estabelecimento: <strong>{{ receitaEstabelecimento }}</strong></v-col>
        </v-row>
        <div class="div-table">
          <h3>Divisão do Lucro Bruto</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Nome</th>
                  <th class="text-center">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="recebedor in summary.recebedores" :key="recebedor.id">
                  <td class="text-center">{{ recebedor.nome }}</td>
                  <td class="text-center">
                    <strong>{{ formatCurrency(recebedor.lucro) }}</strong>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { GETTERS } from '../store/types'
import { formatCurrency, formatDate } from '../utils'
import * as xlsx from 'xlsx';
import { Parser } from 'json2csv'

export default {
  name: 'FinanceSummary',
  data: () => ({
    exportTypeModal: false,
    supplier: null,
  }),
  computed: {
    ...mapState(['prestadores_servico']),
    ...mapGetters({
      getterPedidos: GETTERS.PEDIDOS,
      getterProprietario: GETTERS.PROPRIETARIO,
      getterEstabelecimentoId: GETTERS.ESTABELECIMENTO_ID,
    }),
    summary() {
      const summary = {
        total: 0,
        atendimentos: 0,
        ticket_medio: 0,
        custos: 0,
        impostos: 0,
        markup: 0,
        recebedores: []
      }

      for (const pedido of this.getterPedidos) {
        summary.atendimentos ++
        summary.total += pedido.valor_bruto

        const { split } = pedido
        summary.custos += split.custos 
        summary.impostos += split.impostos
        summary.markup += split.markup

        for (const recebedor of split.recebedores) {
          const rs = summary.recebedores.find(r => r.id === recebedor.id)
          if (rs) {
            rs.lucro += recebedor.lucro
          }
          else {
            const recebedorCopy = JSON.parse(JSON.stringify(recebedor))
            summary.recebedores.push(recebedorCopy)
          }
        }
      }

      if (summary.atendimentos > 0) {
        summary.ticket_medio = summary.total / summary.atendimentos
      }

      if (summary.recebedores.length > 1) {
        summary.recebedores = summary.recebedores.sort((a, b) => b.lucro - a.lucro)
      }

      return summary
    },
    receitaEstabelecimento() {
      let receita = 0

      if (this.getterProprietario && this.summary) {
        const recebedor = this.summary.recebedores.find(r => r.id === this.getterEstabelecimentoId)

        if (recebedor) {
          receita = recebedor.lucro + this.summary.custos + this.summary.impostos
        }
      }
      
      return this.formatCurrency(receita)
    },
    getPedidosParaCSV(){
      return (id)=> {
        const pedidos = this.getterPedidos.filter(pedido => pedido.split.recebedores.filter((r)=>r.id === id).length > 0)
        return pedidos.map((pedido)=>{
          const recebedor = pedido.split.recebedores.filter((r)=>r.id === id)[0]
          return {
            ocupacao: '225',
            natureza: 'R01.001.001',
            data_recebimento: this.formatDate(pedido.data, 'DD/MM/YYYY'),
            recebido_de: 'PF',
            deduction: '0',
            cpf_responsavel_pagamento: pedido.cliente.cpf,
            cpf_beneficiario_servico: pedido.cliente.cpf,
            historico: recebedor.descricao_servico,
            _valor: recebedor.valor,
            valor: recebedor.valor.toFixed(2).replace('.', ',')
          }
        }).filter((pedido)=>pedido._valor > 0)
      }
    },
    getPedidosParaXLSX(){
      return (id)=> {
        const fields = [
          {
            label: 'Data Emissão',
            value: (row) => row.data_recebimento,
          },
          {
            label: 'Cliente',
            value: (row) => row.cliente,
          },
          {
            label: 'CPF/CNPJ',
            value: (row) => row.cpf,
          },
          {
            label: 'Endereço',
            value: (row) => row.endereco,
          },
          {
            label: 'Descrição Serviço',
            value: (row) => row.historico,
          },
          {
            label: 'Valor',
            value: (row) => row.valor
          },
          {
            label: 'Forma Pagamento',
            value: (row) => row.forma_pagamento,
          },
        ]
        let pedidos = this.getterPedidos.filter(pedido => pedido.split.recebedores.filter((r)=>r.id === id).length > 0)
        pedidos = pedidos.map((pedido)=>{
          const recebedor = pedido.split.recebedores.filter((r)=>r.id === id)[0]
          return {
            ocupacao: recebedor.ocupacao,
            natureza: 'Trabalho não assalariado',
            data_recebimento: this.formatDate(pedido.data, 'DD/MM/YYYY'),
            cpf: pedido.cliente.cpf,
            cliente: pedido.cliente.nome,
            endereco: pedido.cliente.endereco,
            historico: recebedor.descricao_servico,
            forma_pagamento: pedido.forma_pagamento,
            _valor: recebedor.valor,
            valor: recebedor.valor.toFixed(2).replace('.', ',')
          }
        }).filter((pedido)=>pedido._valor > 0)
        const r = []
        for (const pedido of pedidos) {
          const row = {}
          for (const field of fields) {
            row[field.label] = field.value(pedido)
          }
          r.push(row)
        }
        return r
      }
    }
  },

  methods: {
    formatDate,
    formatCurrency,
    showExportTypeModal(supplier) {
      this.supplier = supplier
      this.exportTypeModal = true
    },
    closeExportTypeModal() {
      this.supplier = null
      this.exportTypeModal = false
    },
    saveResultCSV(supplier) {
      const fields = [
          'data_recebimento',
          'natureza',
          'ocupacao',
          'deduction',
          'historico',
          'recebido_de',
          'cpf_responsavel_pagamento',
          'cpf_beneficiario_servico',
          'valor'
        ]

      const json2csvParser = new Parser({ fields, header: false })

      const data = this.getPedidosParaCSV(supplier.id)
      const csv = json2csvParser.parse(data)
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      const e = document.createEvent('MouseEvents'),
        a = document.createElement('a')
      a.download = `Livro Caixa ${supplier.nome} ${new Date().toLocaleString()}.csv`
      a.href = window.URL.createObjectURL(blob)
      a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':')
      e.initEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      )
      a.dispatchEvent(e)
      this.closeExportTypeModal()
    },
    saveResultXLSX(supplier) {
      
      const data = this.getPedidosParaXLSX(supplier.id)

      const wb = xlsx.utils.book_new();
      console.log(data);
      const ws = xlsx.utils.json_to_sheet(data);

      xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');

      const blob = new Blob([xlsx.write(wb, { 
        bookType: 'xlsx', 
        type: 'buffer', 
        dateNF: "yyyy-MM-dd'T'HH:mm:ss.SSSZ" 
      })], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });

      const e = document.createEvent('MouseEvents'),
        a = document.createElement('a')
      a.download = `Relatório ${supplier.nome} ${new Date().toLocaleString()}.xlsx`
      a.href = window.URL.createObjectURL(blob)
      e.initEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      )
      a.dispatchEvent(e)
      this.closeExportTypeModal()
    },
  }
}
</script>

<style scoped>
.div-table {
  margin-top: 20px;
}
</style>
