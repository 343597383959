export function formatCurrency(value) {
  value = Number(value)
  if (isNaN(value)) {
    value = 0
  }
  return `R$ ${value.toFixed(2)}`
}

export function formatDate(date, format = 'DD/MM/YYYY HH:mm:ss') {
  const dt = new Date(date)
  if (isNaN(dt.getTime())) {
    return ''
  }

  const day = dt.getDate().toString().padStart(2, '0')
  const month = (dt.getMonth() + 1).toString().padStart(2, '0')
  const year = dt.getFullYear().toString()

  const hour = dt.getHours().toString().padStart(2, '0')
  const minutes = dt.getMinutes().toString().padStart(2, '0')
  const seconds = dt.getSeconds().toString().padStart(2, '0')

  return format
    .replace('DD', day)
    .replace('MM', month)
    .replace('YYYY', year)
    .replace('HH', hour)
    .replace('mm', minutes)
    .replace('ss', seconds)
}

export function toLocalDate(date) {
  if (!date) {
    return ''
  }
  return new Date(`${date}T00:00`).toLocaleDateString()
}

export function toISODate(date) {
  if (!date) {
    return ''
  }
  
  const [day, month, year] = date.split('/')
  return `${year}-${month}-${day}`
}
