import api from '../api'

import { ACTIONS, MUTATIONS } from './types'

export default {
  [ACTIONS.AUTH_CACHE_TOKEN] (_, payload) {
    const { id } = payload

    return new Promise((resolve, reject) => {
      api(false).get('/auth/cache-token', { params: { id } })
      .then(({ data }) => {
        localStorage.setItem('token', data.access_token)
        resolve()
      })
      .catch(e => reject(e.response.data))
    })
  },

  [ACTIONS.VALIDATE_TOKEN] ({ commit }) {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem('token')) {
        return reject('no token')
      }

      api().get('/auth/profile')
        .then(({ data }) => {
          commit(MUTATIONS.SET_AUTH, data)
          resolve(data)
        })
        .catch(e => {
          localStorage.removeItem('token')
          reject(e.response.data)
        })
    })
  },

  [ACTIONS.CREATE_RELATORIO_RECEBIVEIS] (_, payload) {
    const { estabelecimentos, data_inicio, data_final } = payload 
    const data = { estabelecimentos, data_inicio, data_final }

    return new Promise((resolve, reject) => {
      api().post('/relatorios-recebiveis', data)
        .then(() => resolve())
        .catch(() => reject())
    })
  },

  [ACTIONS.FETCH_RELATORIOS_RECEBIVEIS] ({ commit }) {
    return new Promise((resolve, reject) => {
      api().get('/relatorios-recebiveis')
        .then(({ data }) => {
          commit(MUTATIONS.SET_RELATORIOS_RECEBIVEIS, { 
            relatorios_recebiveis: data 
          })
          resolve()
        })
        .catch(() => {
          commit(MUTATIONS.SET_RELATORIOS_RECEBIVEIS, { 
            relatorios_recebiveis: [] 
          })
          reject()
        })
    })
  },

  [ACTIONS.FETCH_ESTABELECIMENTOS] ({ commit }) {
    const query = `
      {
        estabelecimentosByIds {
          id
          nome
        }
      }
    `

    return new Promise((resolve, reject) => {
      api().post('/graphql', { query })
        .then(({ data }) => {
          let estabelecimentos

          if (data.error) {
            console.log(data.error)
            estabelecimentos = []
          }
          else {
            estabelecimentos = data.data.estabelecimentosByIds
          }

          commit(MUTATIONS.SET_ESTABELECIMENTOS, { estabelecimentos })
          resolve()
        })
        .catch(e => {
          console.log(e.response.data)
          commit(MUTATIONS.SET_ESTABELECIMENTOS, { estabelecimentos: [] })
          reject()
        })
    })
  },

  [ACTIONS.FETCH_PEDIDOS] ({ commit, state }, payload) {
    const { 
      //estabelecimento_id, 
      data_inicio, 
      data_fim, 
      status_pedidos,
      prestadores_servico,
      cliente
    } = payload

    const variables = {
      data_inicio,
      data_fim,
      status_pedidos,
      prestadores_servico
    }

    if(prestadores_servico.length === 0) {
      state.prestadores_servico = state.estabelecimentos
    } else {
      state.prestadores_servico = state.estabelecimentos.filter(e => 
        prestadores_servico.indexOf(e.id) !== -1
      )
    }

    if (cliente) {
      variables.cliente = cliente
    }

    const query = `
      query Pedidos(
        $data_inicio: String!
        $data_fim: String!
        $status_pedidos: [String!]
        $prestadores_servico: [String!],
        $cliente: String
      ) {
        pedidos(
          data_inicio: $data_inicio
          data_fim: $data_fim
          status_pedidos: $status_pedidos
          prestadores_servico: $prestadores_servico,
          cliente: $cliente
        ) {
          id
          aparelho
          cliente {
            id
            nome
            data_nascimento
            cpf
            email
            telefone
            endereco
          }
          com_cupom
          data
          status_pedido
          valor
          indicacao_prestador_servico
          prestador_servico {
            id
            nome
          }
          estabelecimento {
            id
            nome
          }
          forma_pagamento
          modo_entrada
          status_pedido
          valor
          valor_bruto
          taxa_delivery
          tipo
          split {
            custos
            impostos
            markup
            recebedores {
              id
              nome
              descricao_servico
              ocupacao
              valor
              lucro
              mudanca_saldo
              saldo_anterior
              saldo_resultante
            }
          }
          produtos {
            id
            nome
            preco
            quantidade
            combo {
              id
              nome
              preco
              quantidade
            }
          }
          observacoes
        }
      }
    `

    return new Promise((resolve, reject) => {
      api().post('/graphql', { query, variables })
        .then(({ data }) => {
          let pedidos

          if (data.error) {
            console.log(data.error)
            pedidos = []
          }
          else {
            pedidos = data.data.pedidos
          }

          commit(MUTATIONS.SET_PEDIDOS, { pedidos })
          resolve()
        })
        .catch(e => {
          console.log(e.response.data)
          commit(MUTATIONS.SET_PEDIDOS, { pedidos: [] })
          reject()
        })
    })
  },

  [ACTIONS.FETCH_PRODUTOS_VENDIDOS] ({ commit }, payload) {
    const { 
      data_inicio, 
      data_fim, 
      cliente_id,
    } = payload

    const variables = {
      data_inicio,
      data_fim,
    }

    if (cliente_id) {
      variables.cliente_id = cliente_id
    }

    const query = `
      fragment ProdutoFrag on Produto {
        id
        nome
        quantidade
      }
      
      query ProdutosVendidos(
        $data_inicio: String!
        $data_fim: String!
        $cliente_id: String
      ) {
        produtos: produtosVendidos(
          data_inicio: $data_inicio
          data_fim: $data_fim,
          listar: PRODUTOS,
          cliente_id: $cliente_id
        ) {
          ...ProdutoFrag
        }
        complementos: produtosVendidos(
          data_inicio: $data_inicio
          data_fim: $data_fim,
          listar: COMPLEMENTOS,
          cliente_id: $cliente_id
        ) {
          ...ProdutoFrag
        }
      }
    `

    return new Promise((resolve, reject) => {
      api().post('/graphql', { query, variables })
        .then(({ data }) => {
          let produtos_vendidos
          let complementos_vendidos

          if (data.error) {
            console.log(data.error)
            produtos_vendidos = []
            complementos_vendidos = []
          }
          else {
            produtos_vendidos = data.data.produtos
            complementos_vendidos = data.data.complementos
          }

          commit(MUTATIONS.SET_PRODUTOS_VENDIDOS, { produtos_vendidos })
          commit(MUTATIONS.SET_COMPLEMENTOS_VENDIDOS, { complementos_vendidos })
          resolve()
        })
        .catch(e => {
          console.log(e.response.data)
          commit(MUTATIONS.SET_PRODUTOS_VENDIDOS, { produtos_vendidos: [] })
          commit(MUTATIONS.SET_COMPLEMENTOS_VENDIDOS, { complementos_vendidos: [] })
          reject()
        })
    })
  },

  [ACTIONS.FETCH_CLIENTE] (_, payload) {
    const { cliente_id } = payload

    const variables = { id: cliente_id }

    const query = `
      query Cliente($id: String!) {
        cliente(id: $id) {
          id
          nome
        }
      }
    `

    return new Promise((resolve, reject) => {
      api().post('/graphql', { query, variables })
        .then(({ data }) => {
          if (data.error) {
            console.log(data.error)
            return reject()
          }

          const { cliente } = data.data

          if (!cliente) {
            return reject()
          }

          resolve(cliente)
        })
        .catch(e => {
          console.log(e.response.data)
          reject()
        })
    })
  },

  [ACTIONS.FETCH_CONSUMO_CLIENTES] ({ commit }, payload) {
    const { 
      data_inicio, 
      data_fim, 
    } = payload

    const variables = {
      data_inicio,
      data_fim,
    }

    const query = `
      query ConsumoClientes(
        $data_inicio: String!
        $data_fim: String!
      ) {
        consumoClientes(
          data_inicio: $data_inicio
          data_fim: $data_fim
        ) {
          id
          nome
          telefone
          consumo_total
          quantidade_pedidos
        }
      }
    `

    return new Promise((resolve, reject) => {
      api().post('/graphql', { query, variables })
        .then(({ data }) => {
          let consumo_clientes

          if (data.error) {
            console.log(data.error)
            consumo_clientes = []
          }
          else {
            consumo_clientes = data.data.consumoClientes
          }

          commit(MUTATIONS.SET_CONSUMO_CLIENTES, { consumo_clientes })
          resolve()
        })
        .catch(e => {
          console.log(e.response.data)
          commit(MUTATIONS.SET_CONSUMO_CLIENTES, { pedidos: [] })
          reject()
        })
    })
  },
}
