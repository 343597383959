import { MUTATIONS } from './types'

export default {
  [MUTATIONS.SET_PEDIDOS] (state, payload) {
    const { pedidos } = payload
    state.pedidos = pedidos
  },

  [MUTATIONS.SET_ESTABELECIMENTOS] (state, payload) {
    const { estabelecimentos } = payload
    state.estabelecimentos = estabelecimentos
  },

  [MUTATIONS.SET_AUTH] (state, payload) {
    const { proprietario, estabelecimento_id } = payload
    state.proprietario = proprietario
    state.estabelecimento_id = estabelecimento_id
  },

  [MUTATIONS.SET_RELATORIOS_RECEBIVEIS] (state, payload) {
    const { relatorios_recebiveis } = payload
    state.relatorios_recebiveis = relatorios_recebiveis
  },

  [MUTATIONS.SET_PRODUTOS_VENDIDOS] (state, payload) {
    const { produtos_vendidos } = payload
    state.produtos_vendidos = produtos_vendidos
  },

  [MUTATIONS.SET_COMPLEMENTOS_VENDIDOS] (state, payload) {
    const { complementos_vendidos } = payload
    state.complementos_vendidos = complementos_vendidos
  },

  [MUTATIONS.SET_CONSUMO_CLIENTES] (state, payload) {
    const { consumo_clientes } = payload
    state.consumo_clientes = consumo_clientes
  },

  [MUTATIONS.SET_DATE] (state, payload) {
    const { initial_date, final_date } = payload
    
    if (initial_date) {
      state.initial_date = initial_date
    }

    if (final_date) {
      state.final_date = final_date
    }
  },
}
