<template>
  <div>
    <v-data-table
      item-key="id"
      class="elevation-1"
      :loading="loading"
      :headers="headers"
      :items="getterPedidos"
      :items-per-page="50"
      :footer-props="footerProps"
      loading-text="Buscando informações"
      no-data-text="Nenhum atendimento encontrado"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Atendimentos</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <finance-summary></finance-summary>
          <relatorio-recebiveis-list></relatorio-recebiveis-list>
        </v-toolbar>
      </template>
      <template v-slot:[`item.cliente`]="{ item }">
        <a 
          @click="navigateProdutos(item.cliente)"
          href="#"
        >{{ item.cliente.nome || 'Não informado' }}</a>
      </template>
      <template v-slot:[`item.telefone`]="{ item }">
        {{ item.cliente.telefone || 'Não informado' }}
      </template>
      <template v-slot:[`item.indicacao_prestador_servico`]="{ item }">
        <v-icon 
          v-if="item.indicacao_prestador_servico"
          color="green"
        >mdi-check-circle</v-icon>
      </template>
      <template v-slot:[`item.data`]="{ item }">
        {{ formatDate(item.data) }}
      </template>
      <template v-slot:[`item.valor_bruto`]="{ item }">
        {{ formatCurrency(item.valor_bruto) }}
      </template>
      <template v-slot:[`item.nome_prestador_servico`]="{ item }">
        {{ item.prestador_servico ? item.prestador_servico.nome : 'Não informado' }}
      </template>
      <template v-slot:[`item.custo_total`]="{ item }">
        {{ formatCurrency(item.split.custos + item.split.impostos) }}
        <v-menu
          open-on-hover
          top
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
              v-on="on" 
              v-bind="attrs" 
              small
            >mdi-information</v-icon>
          </template>
          <v-list>
            <v-list-item>Custos: {{ formatCurrency(item.split.custos) }}</v-list-item>
            <v-list-item>Impostos: {{ formatCurrency(item.split.impostos) }}</v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.lucro_bruto`]="{ item }">
        {{ formatCurrency(item.split.markup) }}
      </template>
      <template v-slot:[`item.lucro_bruto_estabelecimento`]="{ item }">
        {{ formatValue(item, item.estabelecimento, 'lucro') }}
      </template>
      <template v-slot:[`item.lucro_bruto_prestador`]="{ item }">
        {{ formatValue(item, item.prestador_servico, 'lucro') }}
      </template>
      <template v-slot:[`item.valor_estabelecimento`]="{ item }">
        {{ formatValue(item, item.estabelecimento, 'valor') }}
      </template>
      <template v-slot:[`item.valor_prestador`]="{ item }">
        {{ formatValue(item, item.prestador_servico, 'valor') }}
      </template>
      <template v-slot:[`item.saldo_anterior`]="{ item }">
        {{ formatValue(item, item.prestador_servico, 'saldo_anterior') }}
      </template>
      <template v-slot:[`item.mudanca_saldo`]="{ item }">
        {{ formatValue(item, item.prestador_servico, 'mudanca_saldo') }}
      </template>
      <template v-slot:[`item.saldo_resultante`]="{ item }">
        {{ formatValue(item, item.prestador_servico, 'saldo_resultante') }}
      </template>
      <template v-slot:[`item.details`]="{ item }">
        <v-btn icon @click="pedidoSelected = item; showModal = true">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <pedido-details 
      :show="showModal"
      :pedido="pedidoSelected"
      @close-modal="showModal = false"
    ></pedido-details>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GETTERS } from '../store/types'
import ROUTE_NAMES from '../router/route-names'
import { formatCurrency, formatDate } from '../utils'
import FinanceSummary from './FinanceSummary.vue'
import RelatorioRecebiveisList from './RelatorioRecebiveisList.vue'
import PedidoDetails from './PedidoDetails.vue'

export default {
  name: 'PedidosTable',
  
  components: { 
    FinanceSummary, 
    RelatorioRecebiveisList,
    PedidoDetails
  },

  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    showModal: false,
    pedidoSelected: null,
    headers: [
      {
        text: 'Data',
        align: 'center',
        sortable: true,
        value: 'data'
      },
      {
        text: 'Cliente',
        align: 'left',
        sortable: true,
        value: 'cliente'
      },
      {
        text: 'Telefone',
        align: 'left',
        sortable: true,
        value: 'telefone'
      },
      {
        text: 'Prest. Serviço',
        align: 'center',
        sortable: false,
        value: 'nome_prestador_servico'
      },
      {
        text: 'Cliente do Prest.',
        align: 'center',
        sortable: false,
        value: 'indicacao_prestador_servico'
      },
      {
        text: 'Pagamento',
        align: 'center',
        sortable: true,
        value: 'forma_pagamento'
      },
      {
        text: 'Receita',
        align: 'center',
        sortable: true,
        value: 'valor_bruto'
      },
      {
        text: 'Custo Total',
        align: 'center',
        sortable: false,
        value: 'custo_total'
      },
      {
        text: 'Lucro Bruto',
        align: 'center',
        sortable: false,
        value: 'lucro_bruto'
      },
      {
        text: 'Lucro Est.',
        align: 'center',
        sortable: false,
        value: 'lucro_bruto_estabelecimento'
      },
      {
        text: 'Lucro Prest.',
        align: 'center',
        sortable: false,
        value: 'lucro_bruto_prestador'
      },
      {
        text: 'Valor Est.',
        align: 'center',
        sortable: false,
        value: 'valor_estabelecimento'
      },
      {
        text: 'Valor Prest.',
        align: 'center',
        sortable: false,
        value: 'valor_prestador'
      },
      {
        text: 'Saldo Ant.',
        align: 'center',
        sortable: false,
        value: 'saldo_anterior'
      },
      {
        text: 'Mud. Saldo',
        align: 'center',
        sortable: false,
        value: 'mudanca_saldo'
      },
      {
        text: 'Saldo Fut.',
        align: 'center',
        sortable: false,
        value: 'saldo_resultante'
      },
      { 
        text: '', 
        sortable: false,
        value: 'details' 
      },
    ],
    footerProps: {
      itemsPerPageAllText: 'Todos',
      itemsPerPageOptions: [10, 20, 50, 100, -1]
    }
  }),

  computed: {
    ...mapGetters({
      getterPedidos: GETTERS.PEDIDOS,
      getterProprietario: GETTERS.PROPRIETARIO
    })
  },

  methods: {
    formatCurrency,
    formatDate,
    formatValue(item, estabelecimento, campo) {
      if (!estabelecimento) {
        return '---'
      }

      const recebedor = item.split.recebedores.find(i => i.id === estabelecimento.id)
      if (!recebedor) {
        return '---'
      }

      return this.formatCurrency(recebedor[campo])
    },
    navigateProdutos(cliente) {
      if (!cliente) {
        return
      }

      const query = {}

      if (cliente.id) {
        query.cliente_id = cliente.id
      }

      if (this.getterProprietario) {
        this.$router.push({ name: ROUTE_NAMES.PRODUTOS_REPORT, query })
      }
    }
  }
}
</script>

<style scoped>
.split-list li {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
