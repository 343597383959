<template>
  <div>
    <v-app-bar dense>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title>Relatórios</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list nav>
        <v-list-item @click="navigatePedidos">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Atendimentos</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="getterProprietario" 
          @click="navigateProdutos"
        >
          <v-list-item-icon>
            <v-icon>mdi-package-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Produtos vendidos</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="getterProprietario" 
          @click="navigateConsumo"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Consumo dos Clientes</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ACTIONS, GETTERS } from '../store/types'
import ROUTE_NAMES from '../router/route-names'

export default {
  name: 'Home',

  data: () => ({
    drawer: false
  }),

  computed: {
    ...mapGetters({
      getterProprietario: GETTERS.PROPRIETARIO,
    }),
  },

  methods: {
    ...mapActions({
      actionValidateToken: ACTIONS.VALIDATE_TOKEN
    }),
    navigatePedidos() {
      this.navigate(ROUTE_NAMES.PEDIDOS_REPORT)
    },
    navigateProdutos() {
      this.navigate(ROUTE_NAMES.PRODUTOS_REPORT)
    },
    navigateConsumo() {
      this.navigate(ROUTE_NAMES.CONSUMO_REPORT)
    },
    navigate(name) {
      if (this.$route.name !== name) {
        this.$router.push({ name })
      }
    }
  },

  mounted() {
    this.actionValidateToken()
      .then(data => {
        console.log(data)
        if (this.$route.name !== ROUTE_NAMES.PEDIDOS_REPORT) {
          this.$router.push({ name: ROUTE_NAMES.PEDIDOS_REPORT })
        }
      })
      .catch(e => {
        console.log(e)
        this.$router.push({ name: ROUTE_NAMES.CACHE_TOKEN })
      })
  }
}
</script>
