<template>
  <div class="progress-circular">
    <v-progress-circular
      :size="50"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { ACTIONS } from '../store/types'
import ROUTE_NAMES from '../router/route-names'

export default {
  name: 'CacheToken',

  methods: {
    ...mapActions({
      actionAuthCacheToken: ACTIONS.AUTH_CACHE_TOKEN
    })
  },

  mounted() {
    const { id } = this.$route.query
    if (!id) {
      return
    }

    this.actionAuthCacheToken({ id })
      .then(data => {
        console.log(data)
        this.$router.push({ name: ROUTE_NAMES.HOME })
      })
      .catch(e => console.log(e))
  }
}
</script>

<style scoped>
.progress-circular {
  position: fixed;
  left: 48%;
  top: 48%;
}
</style>
