<template>
  <div>
    <v-data-table
      item-key="id"
      class="elevation-1"
      :loading="loading"
      :headers="headers"
      :items="produtos"
      :items-per-page="50"
      :footer-props="footerProps"
      loading-text="Buscando informações"
      no-data-text="Nenhum produto encontrado"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-btn 
            color="error"
            v-if="showButton" 
            @click="emitRemoveFilter"
          >Remover filtro de cliente</v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'PedidosTable',

  props: {
    loading: {
      type: Boolean,
      required: true
    },
    cliente: {
      type: Object,
      required: false
    },
    produtos: {
      required: true
    }
  },

  data: () => ({
    headers: [
      {
        text: 'Produto',
        align: 'left',
        sortable: true,
        value: 'nome'
      },
      {
        text: 'Quantidade',
        align: 'left',
        sortable: true,
        value: 'quantidade'
      },
    ],
    footerProps: {
      itemsPerPageAllText: 'Todos',
      itemsPerPageOptions: [10, 20, 50, 100, -1]
    }
  }),

  computed: {
    title() {
      return this.cliente ? `Cliente: ${this.cliente.nome}` : 'Sem cliente selecionado'
    },
    showButton() {
      return !!this.cliente
    }
  },

  methods: {
    emitRemoveFilter() {
      this.$emit('remove-cliente-filter')
    }
  }
}
</script>
