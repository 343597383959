<template>
  <v-container>
    <period-filter></period-filter>
    <v-row>
      <v-col>
        <prestadores-servico-filter 
          @prestadores-servico-change="prestadoresServico = $event"
        ></prestadores-servico-filter>
      </v-col>
      <v-col>
        <pedido-status-filter 
          @pedido-status-change="statusPedido = $event"
        ></pedido-status-filter>
      </v-col>
    </v-row>
    <v-row>
      <client-filter
        @client-change="client = $event"
      ></client-filter>
    </v-row>
    <v-btn 
      color="primary"
      @click="eventFilter"
    >Filtrar</v-btn>
  </v-container>
</template>

<script>
import ClientFilter from './ClientFilter.vue'
import PedidoStatusFilter from './PedidoStatusFilter.vue'
import PeriodFilter from './PeriodFilter.vue'
import PrestadoresServicoFilter from './PrestadoresServicoFilter.vue'

export default {
  name: 'PedidosFilter',

  components: { 
    PeriodFilter, 
    PrestadoresServicoFilter, 
    PedidoStatusFilter, 
    ClientFilter 
  },

  data: () => ({
    prestadoresServico: [],
    statusPedido: [],
    client: ''
  }),

  methods: {
    eventFilter() {
      const prestadores_servico = []
      const status_pedidos = []

      for (const item of this.prestadoresServico) {
        prestadores_servico.push(item)
      }

      for (const item of this.statusPedido) {
        status_pedidos.push(item)
      }

      const filter = {
        prestadores_servico,
        status_pedidos,
      }

      if (this.client) {
        filter.cliente = this.client
      }

      this.$emit('filter', filter)
    }
  }
}
</script>
