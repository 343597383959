<template>
  <v-dialog v-model="openModal" width="800">
    <v-card>
      <v-card-title>Detalhes do Atendimento</v-card-title>
      <v-card-text>
        <v-tabs v-model="tab">
          <v-tab href="#cliente">Cliente</v-tab>
          <v-tab href="#produtos">Produtos</v-tab>
          <v-tab href="#pagamento">Pagamento</v-tab>
          <v-tab href="#observacoes">Observações</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <div class="container">
          <v-tabs-items v-model="tab">
            <v-tab-item value="cliente">
              <v-row>
                <v-col cols="2">Nome:</v-col>
                <v-col>{{ nomeCliente }}</v-col>
              </v-row>
              <v-row v-if="dataNascimento">
                <v-col cols="2">Nascimento:</v-col>
                <v-col>{{ dataNascimento }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="2">CPF:</v-col>
                <v-col>{{ cpf }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="2">Telefone:</v-col>
                <v-col>{{ telefone }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="2">Email:</v-col>
                <v-col>{{ email }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="2">Endereço:</v-col>
                <v-col>{{ endereco }}</v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="produtos">
              <v-row v-for="(produto, index) in produtos" :key="index">
                <template v-if="produto.combo">
                  <v-col cols="1"></v-col>
                  <v-col>
                    {{ produto.quantidade }} x 
                    <span v-if="produto.preco > 0">({{ formatCurrency(produto.preco) }})</span> 
                    {{ produto.nome }}
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="1">{{ produto.quantidade }} x</v-col>
                  <v-col>{{ produto.nome }}</v-col>
                  <v-col cols="2">{{ formatCurrency(produto.preco) }}</v-col>
                </template>
              </v-row>
            </v-tab-item>
            <v-tab-item value="pagamento">
              <v-row>
                <v-col>Valor pago: <strong>{{ formatCurrency(valorBruto) }}</strong></v-col>
                <v-col>Forma de pagamento: 
                  <strong>{{ formaPagamento }} {{ formatEntryMode(modoEntrada) }}</strong>
                </v-col>
              </v-row>
              <v-row>
                <v-col>Custos: <strong>{{ formatCurrency(split.custos) }}</strong></v-col>
                <v-col>Impostos: <strong>{{ formatCurrency(split.impostos) }}</strong></v-col>
                <v-col>Lucro bruto: <strong>{{ formatCurrency(split.markup) }}</strong></v-col>  
              </v-row>
              <v-row v-if="getterProprietario">
                <v-col>Receita do estabelecimento: <strong>{{ receitaEstabelecimento }}</strong></v-col>
              </v-row>
              <div class="div-table">
                <h3>Divisão do Lucro bruto</h3>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Nome</th>
                        <th class="text-center">Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="recebedor in split.recebedores" :key="recebedor.id">
                        <td class="text-center">{{ recebedor.nome }}</td>
                        <td class="text-center">
                          <strong>{{ formatCurrency(recebedor.lucro) }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-tab-item>
            <v-tab-item value="observacoes">
              <p v-if="observacoesPedido.length === 0">Sem observações</p>
              <template v-else>
                <p 
                  v-for="(observacao, index) in observacoesPedido" 
                  :key="index"
                >{{ observacao }}</p>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-menu
          top
          offset-y
          v-if="temPrestador"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              icon 
              v-bind="attrs"
              v-on="on"
              :loading="printerLoading" 
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="printReciboPrestacaoServico()"
            >Recibo de Prestação de Serviço</v-list-item>
            <v-list-item
              @click="printReciboPagamento()"
              v-if="pagoEmDinheiro"
            >Recibo de Pagamento</v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import printJS from 'print-js'
import { mapGetters } from 'vuex'
import { GETTERS } from '../store/types'
import { formatCurrency } from '../utils'

export default {
  name: 'PedidoDetails',

  props: ['show', 'pedido'],

  data: () => ({
    tab: 'cliente',
    openModal: false,
    printerLoading: false
  }),

  computed: {
    ...mapGetters({
      getterProprietario: GETTERS.PROPRIETARIO,
      getterEstabelecimentoId: GETTERS.ESTABELECIMENTO_ID,
    }),
    produtos() {
      const produtos = []

      if (this.pedido) {
        for (const produto of this.pedido.produtos) {
          const { combo, ...restProduto } = produto
          produtos.push({ ...restProduto, combo: false })
  
          for (const produtoCombo of combo) {
            produtos.push({ ...produtoCombo, combo: true })
          }
        }
      }

      return produtos
    },
    split() {
      if (!this.pedido) {
        return {}
      }
      return this.pedido.split
    },
    receitaEstabelecimento() {
      let receita = 0

      if (this.getterProprietario && this.pedido) {
        const estabelecimento = this.split.recebedores.find(r => r.id === this.getterEstabelecimentoId)

        if (estabelecimento) {
          receita = estabelecimento.lucro + this.split.custos + this.split.impostos
        }
      }
      
      return this.formatCurrency(receita)
    },
    nomeCliente() {
      if (!this.pedido) {
        return ''
      }
      return this.pedido.cliente.nome
    },
    dataNascimento() {
      if (!this.pedido) {
        return ''
      }
      return this.pedido.cliente.data_nascimento
    },
    cpf() {
      if (!this.pedido) {
        return ''
      }
      return this.pedido.cliente.cpf
    },
    telefone() {
      if (!this.pedido) {
        return ''
      }
      return this.pedido.cliente.telefone
    },
    email() {
      if (!this.pedido) {
        return ''
      }
      return this.pedido.cliente.email
    },
    endereco() {
      if (!this.pedido) {
        return ''
      }
      return this.pedido.cliente.endereco
    },
    valorBruto() {
      if (!this.pedido) {
        return 0
      }
      return this.pedido.valor_bruto
    },
    formaPagamento() {
      if (!this.pedido) {
        return ''
      }
      return this.pedido.forma_pagamento || '---'
    },
    modoEntrada() {
      if (!this.pedido) {
        return ''
      }
      return this.pedido.modo_entrada
    },
    observacoesPedido() {
      if (!this.pedido) {
        return []
      }
      return this.pedido.observacoes
    },
    temPrestador() {
      if (!this.split.recebedores) {
        return false
      }
      return this.split.recebedores.length > 1
    },
    pagoEmDinheiro() {
      return this.formaPagamento === 'Dinheiro'
    }
  },

  watch: {
    show() {
      this.tab = 'cliente'
      if (this.show) {
        this.openModal = true
      }
    },
    openModal() {
      if (!this.openModal) {
        this.$emit('close-modal')
      }
    }
  },

  methods: {
    formatCurrency,
    formatEntryMode(entry_mode) {
      if (!entry_mode) {
        return ''
      }

      return `(${entry_mode})`
    },
    printReciboPrestacaoServico() {
      this.printerLoading = true
      const order_id = this.pedido.id
      
      const promise = axios.get('https://print.socialplace.com.br/docs/recibo-prestacao-servico', { 
        params: { order_id },
        responseType: 'blob'
      })

      this.handlePrintPromise(promise)
    },
    printReciboPagamento() {
      this.printerLoading = true
      const order_id = this.pedido.id

      const promise = axios.get('https://print.socialplace.com.br/docs/recibo-pagamento', { 
        params: { order_id },
        responseType: 'blob'
      })

      this.handlePrintPromise(promise)
    },
    handlePrintPromise(promise) {
      promise
        .then(response => {
          const reader = new FileReader()
          reader.readAsDataURL(response.data)

          reader.onload = function() {
            const pb64 = reader.result.split(',')[1]
            printJS({ printable: pb64, type: 'pdf', base64: true })
          }
        })
        .catch(error => console.log(error))
        .finally(() => this.printerLoading = false)
    }
  }
}
</script>

<style scoped>
.container {
  color: black;
}

.div-table {
  margin-top: 20px;
}
</style>
