<template>
  <div>
    <produtos-filter 
      @filter="filter"
    ></produtos-filter>
    <v-tabs v-model="tab">
      <v-tab href="#produtos">Produtos</v-tab>
      <v-tab href="#complementos">Complementos</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item value="produtos">
        <produtos-table 
          :loading="loading"
          :cliente="cliente"
          :produtos="getterProdutosVendidos"
          @remove-cliente-filter="removeClienteFilter"
        ></produtos-table>
      </v-tab-item>
      <v-tab-item value="complementos">
        <produtos-table 
          :loading="loading"
          :cliente="cliente"
          :produtos="getterComplementosVendidos"
          @remove-cliente-filter="removeClienteFilter"
        ></produtos-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ACTIONS, GETTERS, MUTATIONS } from '../store/types'
import ROUTE_NAMES from '../router/route-names'
import ProdutosFilter from '../components/ProdutosFilter'
import ProdutosTable from '../components/ProdutosTable'

export default {
  name: 'ProdutosReport',

  components: { 
    ProdutosFilter, 
    ProdutosTable, 
  },

  data: () => ({
    tab: 'produtos',
    loading: false,
    cliente: null
  }),

  computed: {
    ...mapGetters({
      getterProprietario: GETTERS.PROPRIETARIO,
      getterInitialDate: GETTERS.INITIAL_DATE,
      getterFinalDate: GETTERS.FINAL_DATE,

      getterProdutosVendidos: GETTERS.PRODUTOS_VENDIDOS,
      getterComplementosVendidos: GETTERS.COMPLEMENTOS_VENDIDOS,
    }),
  },

  methods: {
    ...mapActions({
      actionFetchProdutosVendidos: ACTIONS.FETCH_PRODUTOS_VENDIDOS,
      actionFetchCliente: ACTIONS.FETCH_CLIENTE,
    }),
    ...mapMutations({
      mutationSetProdutosVendidos: MUTATIONS.SET_PRODUTOS_VENDIDOS,
      mutationSetComplementosVendidos: MUTATIONS.SET_COMPLEMENTOS_VENDIDOS,
    }),
    filter() {
      this.loading = true

      const payload = {
        data_inicio: this.getterInitialDate,
        data_fim: this.getterFinalDate,
      }

      const { cliente_id } = this.$route.query

      if (cliente_id) {
        payload.cliente_id = cliente_id
      }

      this.actionFetchProdutosVendidos(payload)
        .finally(() => this.loading = false)
    },
    removeClienteFilter() {
      this.$router.replace({ query: {} })

      this.mutationSetProdutosVendidos({ produtos_vendidos: [] })
      this.mutationSetComplementosVendidos({ complementos_vendidos: [] })

      this.cliente = null
    }
  },

  mounted() {
    if (!this.getterProprietario) {
      return this.$router.push({ name: ROUTE_NAMES.PEDIDOS_REPORT })
    }
    
    (async () => {
      const { cliente_id } = this.$route.query

      if (cliente_id) {
        try {
          this.cliente = await this.actionFetchCliente({ cliente_id })
        }
        catch {
          this.$router.replace({ query: {} })
        }
      }

      this.filter()
    })()
  }
}
</script>
