var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","loading":_vm.loading,"headers":_vm.headers,"items":_vm.getterPedidos,"items-per-page":50,"footer-props":_vm.footerProps,"loading-text":"Buscando informações","no-data-text":"Nenhum atendimento encontrado"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Atendimentos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('finance-summary'),_c('relatorio-recebiveis-list')],1)]},proxy:true},{key:"item.cliente",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.navigateProdutos(item.cliente)}}},[_vm._v(_vm._s(item.cliente.nome || 'Não informado'))])]}},{key:"item.telefone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cliente.telefone || 'Não informado')+" ")]}},{key:"item.indicacao_prestador_servico",fn:function(ref){
var item = ref.item;
return [(item.indicacao_prestador_servico)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_vm._e()]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.data))+" ")]}},{key:"item.valor_bruto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.valor_bruto))+" ")]}},{key:"item.nome_prestador_servico",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.prestador_servico ? item.prestador_servico.nome : 'Não informado')+" ")]}},{key:"item.custo_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.split.custos + item.split.impostos))+" "),_c('v-menu',{attrs:{"open-on-hover":"","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[_c('v-list',[_c('v-list-item',[_vm._v("Custos: "+_vm._s(_vm.formatCurrency(item.split.custos)))]),_c('v-list-item',[_vm._v("Impostos: "+_vm._s(_vm.formatCurrency(item.split.impostos)))])],1)],1)]}},{key:"item.lucro_bruto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.split.markup))+" ")]}},{key:"item.lucro_bruto_estabelecimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(item, item.estabelecimento, 'lucro'))+" ")]}},{key:"item.lucro_bruto_prestador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(item, item.prestador_servico, 'lucro'))+" ")]}},{key:"item.valor_estabelecimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(item, item.estabelecimento, 'valor'))+" ")]}},{key:"item.valor_prestador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(item, item.prestador_servico, 'valor'))+" ")]}},{key:"item.saldo_anterior",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(item, item.prestador_servico, 'saldo_anterior'))+" ")]}},{key:"item.mudanca_saldo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(item, item.prestador_servico, 'mudanca_saldo'))+" ")]}},{key:"item.saldo_resultante",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(item, item.prestador_servico, 'saldo_resultante'))+" ")]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.pedidoSelected = item; _vm.showModal = true}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)}),_c('pedido-details',{attrs:{"show":_vm.showModal,"pedido":_vm.pedidoSelected},on:{"close-modal":function($event){_vm.showModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }