import axios from 'axios'

export default function api(auth = true) {
  const config = {
    baseURL: process.env.VUE_APP_API_URL,
  }

  if (auth) {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers = {
        Authorization: `JWT ${token}`,
      }
    }
  }

  return axios.create(config)
}
