<template>
  <div>
    <v-row>
      <v-col>
        <v-menu
          v-model="initialDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="getterInitialDateLocal"
              label="Data inicial"
              prepend-icon="mdi-event"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker no-title @input="selectInitialDate" />
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="finalDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="getterFinalDateLocal"
              label="Data final"
              prepend-icon="mdi-event"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker no-title @input="selectFinalDate" />
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { GETTERS, MUTATIONS } from '../store/types'
import { toISODate } from '../utils'

export default {
  name: 'PeriodFilter',

  data: () => ({
    initialDateMenu: false,
    finalDateMenu: false,
  }),

  computed: {
    ...mapGetters({
      getterInitialDate: GETTERS.INITIAL_DATE,
      getterFinalDate: GETTERS.FINAL_DATE,
      getterInitialDateLocal: GETTERS.INITIAL_DATE_LOCAL,
      getterFinalDateLocal: GETTERS.FINAL_DATE_LOCAL,
    }),
  },

  methods: {
    ...mapMutations({
      mutationSetDate: MUTATIONS.SET_DATE,
    }),
    selectInitialDate(initial_date) {
      const mutation = { 
        initial_date,
        final_date: null 
      }

      if (initial_date > this.getterFinalDate) {
        mutation.final_date = initial_date
      }

      this.mutationSetDate(mutation)
      this.initialDateMenu = false
    },
    selectFinalDate(final_date) {
      this.mutationSetDate({ final_date })
      this.finalDateMenu = false
    },
    toISODate,
  },

  mounted() {
    if (this.getterInitialDate && this.getterFinalDate) {
      return
    }

    const today = new Date().toLocaleDateString()
    const date = this.toISODate(today)

    this.mutationSetDate({
      initial_date: date,
      final_date: date,
    })
  }
}
</script>
