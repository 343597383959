export default {
  proprietario: false,
  estabelecimento_id: '',
  estabelecimentos: [],

  initial_date: '',
  final_date: '',

  prestadores_servico: [],

  pedidos: [],
  relatorios_recebiveis: [],
  produtos_vendidos: [],
  complementos_vendidos: [],
  consumo_clientes: [],
}
