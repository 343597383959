<template>
  <div>
    <consumo-filter @filter="filter"></consumo-filter>
    <consumo-table :loading="loading"></consumo-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ACTIONS, GETTERS, MUTATIONS } from '../store/types'
import ROUTE_NAMES from '../router/route-names'
import ConsumoFilter from '../components/ConsumoFilter'
import ConsumoTable from '../components/ConsumoTable'

export default {
  name: 'ConsumoReport',

  components: { 
    ConsumoTable, 
    ConsumoFilter, 
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapGetters({
      getterProprietario: GETTERS.PROPRIETARIO,
      getterInitialDate: GETTERS.INITIAL_DATE,
      getterFinalDate: GETTERS.FINAL_DATE,
    }),
  },

  methods: {
    ...mapActions({
      actionFetchConsumoClientes: ACTIONS.FETCH_CONSUMO_CLIENTES,
    }),
    ...mapMutations({
      mutationConsumoClientes: MUTATIONS.SET_CONSUMO_CLIENTES,
    }),
    filter() {
      this.loading = true

      const payload = {
        data_inicio: this.getterInitialDate,
        data_fim: this.getterFinalDate,
      }

      this.actionFetchConsumoClientes(payload)
        .finally(() => this.loading = false)
    },
  },

  mounted() {
    if (!this.getterProprietario) {
      return this.$router.push({ name: ROUTE_NAMES.PEDIDOS_REPORT })
    }
    this.filter()
  }
}
</script>
