<template>
  <v-text-field
    v-model="client"
    label="Cliente (nome ou número de celular)"
    @input="change"
  ></v-text-field>
</template>

<script>
export default {
  name: 'ClientFilter',

  data: () => ({
    client: ''
  }),

  methods: {
    change() {
      this.$emit('client-change', this.client)
    }
  }
}
</script>
