<template>
  <v-dialog width="1000" @input="open = $event">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="success"
        dark
        v-bind="attrs"
        v-on="on"
        style="margin-left: 10px"
      >
        Conciliação Bancária
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Conciliação Bancária</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-menu
              v-model="initialDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="getterInitialDateLocal"
                  label="Data inicial"
                  prepend-icon="mdi-event"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker no-title @input="selectInitialDate" />
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="finalDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="getterFinalDateLocal"
                  label="Data final"
                  prepend-icon="mdi-event"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker no-title @input="selectFinalDate" />
            </v-menu>
          </v-col>
          <v-col>
            <prestadores-servico-filter
              @prestadores-servico-change="estabelecimentos = $event"
            ></prestadores-servico-filter>
          </v-col>
          <v-col>
            <v-btn
              color="success"
              dark
              @click="createRelatorioRecebiveis"
            >
              Criar Relatório
            </v-btn>
          </v-col>
        </v-row>
        <div class="div-table">
          <h3>Relatórios</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Data início</th>
                  <th class="text-center">Data final</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Link</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="relatorio in getterRelatoriosRecebiveis" :key="relatorio._id">
                  <td class="text-center">{{ relatorio.data_inicio }}</td>
                  <td class="text-center">{{ relatorio.data_final }}</td>
                  <td class="text-center">{{ statusRelatorio(relatorio.status) }}</td>
                  <td class="text-center">
                    <a 
                      v-if="relatorio.status === 'succeeded'"
                      :href="link(relatorio)"
                    >
                      {{ relatorio.nome_arquivo }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { GETTERS, ACTIONS, MUTATIONS } from '../store/types'
import { formatCurrency, toISODate } from '../utils'
import PrestadoresServicoFilter from './PrestadoresServicoFilter.vue'

export default {
  name: 'RelatorioRecebiveisList',

  components: {
    PrestadoresServicoFilter
  },

  data() {
    const today = new Date().toLocaleDateString()
    const dateSelected = this.toISODate(today)

    return {
      open: false,
      initialDateMenu: false,
      finalDateMenu: false,

      inicialDate: today,
      finalDate: today,

      initialDateSelected: dateSelected,
      finalDateSelected: dateSelected,

      estabelecimentos: [],
      relatorios: [],
    }
  },

  computed: {
    ...mapGetters({
      getterPedidos: GETTERS.PEDIDOS,
      getterRelatoriosRecebiveis: GETTERS.RELATORIOS_RECEBIVEIS,

      getterInitialDate: GETTERS.INITIAL_DATE,
      getterFinalDate: GETTERS.FINAL_DATE,
      getterInitialDateLocal: GETTERS.INITIAL_DATE_LOCAL,
      getterFinalDateLocal: GETTERS.FINAL_DATE_LOCAL,
    }),
  },

  watch: {
    open() {
      if (this.open) {
        this.actionFetchRelatoriosRecebiveis()
      }
    }
  },

  methods: {
    ...mapActions({
      actionCreateRelatorioRecebiveis: ACTIONS.CREATE_RELATORIO_RECEBIVEIS,
      actionFetchRelatoriosRecebiveis: ACTIONS.FETCH_RELATORIOS_RECEBIVEIS,
    }),
    ...mapMutations({
      mutationSetDate: MUTATIONS.SET_DATE,
    }),
    selectInitialDate(initial_date) {
      const mutation = { 
        initial_date,
        final_date: null 
      }

      if (initial_date > this.getterFinalDate) {
        mutation.final_date = initial_date
      }

      this.mutationSetDate(mutation)
      this.initialDateMenu = false
    },
    selectFinalDate(final_date) {
      this.mutationSetDate({ final_date })
      this.finalDateMenu = false
    },
    link(relatorio) {
      return `${process.env.VUE_APP_API_URL}/relatorios-recebiveis/${relatorio._id}/download`
    },
    statusRelatorio(status) {
      if (status === 'pending') {
        return 'Gerando relatório'
      }
      if (status === 'error') {
        return 'Erro ao gerar relatório'
      }
      if (status === 'succeeded') {
        return 'Relatório gerado com sucesso'
      } 
      return '-'
    },
    createRelatorioRecebiveis() {
      const data = {
        data_inicio: this.getterInitialDate,
        data_final: this.getterFinalDate,
        estabelecimentos: this.estabelecimentos,
      }
      this.actionCreateRelatorioRecebiveis(data)
        .then(() => this.actionFetchRelatoriosRecebiveis())
    },
    formatCurrency,
    toISODate,
  }
}
</script>

<style scoped>
.div-table {
  margin-top: 20px;
}
</style>
