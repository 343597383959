export const ACTIONS = {
  AUTH_CACHE_TOKEN: 'AUTH_CACHE_TOKEN',
  VALIDATE_TOKEN: 'VALIDATE_TOKEN',

  FETCH_PEDIDOS: 'FETCH_PEDIDOS',
  FETCH_ESTABELECIMENTOS: 'FETCH_ESTABELECIMENTOS',

  FETCH_RELATORIOS_RECEBIVEIS: 'FETCH_RELATORIOS_RECEBIVEIS',
  CREATE_RELATORIO_RECEBIVEIS: 'CREATE_RELATORIO_RECEBIVEIS',

  FETCH_PRODUTOS_VENDIDOS: 'FETCH_PRODUTOS_VENDIDOS',
  FETCH_CLIENTE: 'FETCH_CLIENTE',
  FETCH_CONSUMO_CLIENTES: 'FETCH_CONSUMO_CLIENTES',
}

export const GETTERS = {
  PEDIDOS: 'PEDIDOS',
  ESTABELECIMENTOS: 'ESTABELECIMENTOS',
  ESTABELECIMENTO_ID: 'ESTABELECIMENTO_ID',
  PROPRIETARIO: 'PROPRIETARIO',
  RELATORIOS_RECEBIVEIS: 'RELATORIOS_RECEBIVEIS',
  PRODUTOS_VENDIDOS: 'PRODUTOS_VENDIDOS',
  COMPLEMENTOS_VENDIDOS: 'COMPLEMENTOS_VENDIDOS',
  CONSUMO_CLIENTES: 'CONSUMO_CLIENTES',
  INITIAL_DATE: 'INITIAL_DATE',
  FINAL_DATE: 'FINAL_DATE',
  INITIAL_DATE_LOCAL: 'INITIAL_DATE_LOCAL',
  FINAL_DATE_LOCAL: 'FINAL_DATE_LOCAL',
}

export const MUTATIONS = {
  SET_PEDIDOS: 'SET_PEDIDOS',
  SET_ESTABELECIMENTOS: 'SET_ESTABELECIMENTOS',
  SET_AUTH: 'SET_AUTH',
  SET_RELATORIOS_RECEBIVEIS: 'SET_RELATORIOS_RECEBIVEIS',
  SET_PRODUTOS_VENDIDOS: 'SET_PRODUTOS_VENDIDOS',
  SET_COMPLEMENTOS_VENDIDOS: 'SET_COMPLEMENTOS_VENDIDOS',
  SET_CONSUMO_CLIENTES: 'SET_CONSUMO_CLIENTES',
  SET_DATE: 'SET_DATE',
}
