<template>
  <v-autocomplete
    v-model="estabelecimentosSelected"
    :items="getterEstabelecimentos"
    item-value="id"
    item-text="nome"
    label="Prestadores de Serviço"
    no-data-text="Nenhum prestador de serviço encontrado"
    chips
    small-chips
    multiple
  ></v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'
import { GETTERS } from '../store/types'

export default {
  name: 'PrestadoresServicoFilter',

  data: () => ({
    estabelecimentosSelected: []
  }),

  computed: {
    ...mapGetters({
      getterEstabelecimentos: GETTERS.ESTABELECIMENTOS
    })
  },

  watch: {
    estabelecimentosSelected() {
      this.eventPrestadoresServicoChange()
    }
  },

  methods: {
    eventPrestadoresServicoChange() {
      const eventPayload = []

      for (const item of this.estabelecimentosSelected) {
        eventPayload.push(item)
      }

      this.$emit('prestadores-servico-change', eventPayload)
    }
  },

  mounted() {
    this.eventPrestadoresServicoChange()
  }
}
</script>
