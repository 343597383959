<template>
  <div>
    <v-data-table
      item-key="id"
      class="elevation-1"
      :loading="loading"
      :headers="headers"
      :items="getterConsumoClientes"
      :items-per-page="50"
      :footer-props="footerProps"
      loading-text="Buscando informações"
      no-data-text="Nenhum produto encontrado"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Consumo dos Clientes</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
        </v-toolbar>
      </template>
      <template v-slot:[`item.telefone`]="{ item }">
        {{ formatPhone(item.telefone) }}
      </template>
      <template v-slot:[`item.consumo_total`]="{ item }">
        {{ formatCurrency(item.consumo_total) }}
      </template>
      <template v-slot:[`item.ticket_medio`]="{ item }">
        {{ formatCurrency(item.ticket_medio) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GETTERS } from '../store/types'
import { formatCurrency } from '../utils'

export default {
  name: 'ConsumoTable',

  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    headers: [
      {
        text: 'Cliente',
        align: 'left',
        sortable: true,
        value: 'nome'
      },
      {
        text: 'Telefone',
        align: 'left',
        sortable: false,
        value: 'telefone'
      },
      {
        text: 'Consumo Total',
        align: 'left',
        sortable: true,
        value: 'consumo_total'
      },
      {
        text: 'Quantidade de Atendimentos',
        align: 'left',
        sortable: true,
        value: 'quantidade_pedidos'
      },
      {
        text: 'Ticket Médio',
        align: 'left',
        sortable: true,
        value: 'ticket_medio'
      },
    ],
    footerProps: {
      itemsPerPageAllText: 'Todos',
      itemsPerPageOptions: [10, 20, 50, 100, -1]
    }
  }),

  computed: {
    ...mapGetters({
      getterConsumoClientes: GETTERS.CONSUMO_CLIENTES
    }),
  },

  methods: {
    formatCurrency,
    formatPhone(phone) {
      if (!phone) {
        return '---'
      }
      return Array.from(phone).filter(c => '0123456789'.includes(c)).join('')
    }
  }
}
</script>
