<template>
  <v-container>
    <period-filter></period-filter>
    <v-btn 
      color="primary"
      @click="eventFilter"
    >Filtrar</v-btn>
  </v-container>
</template>

<script>
import PeriodFilter from './PeriodFilter.vue'

export default {
  name: 'ConsumoFilter',

  components: { 
    PeriodFilter,  
  },

  methods: {
    eventFilter() {
      this.$emit('filter')
    }
  }
}
</script>
