import Vue from 'vue'
import VueRouter from 'vue-router'

import CacheToken from '../views/CacheToken'
import Home from '../views/Home'
import PedidosReport from '../views/PedidosReport'
import ProdutosReport from '../views/ProdutosReport'
import ConsumoReport from '../views/ConsumoReport'

import ROUTE_NAMES from './route-names'

Vue.use(VueRouter)

const routes = [
  {
    path: '/cache-token',
    name: ROUTE_NAMES.CACHE_TOKEN,
    component: CacheToken,
  },
  {
    path: '/',
    name: ROUTE_NAMES.HOME,
    component: Home,
    children: [
      {
        path: '/pedidos-report',
        name: ROUTE_NAMES.PEDIDOS_REPORT,
        component: PedidosReport,
      },
      {
        path: '/produtos-report',
        name: ROUTE_NAMES.PRODUTOS_REPORT,
        component: ProdutosReport,
      },
      {
        path: '/consumo-report',
        name: ROUTE_NAMES.CONSUMO_REPORT,
        component: ConsumoReport,
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
