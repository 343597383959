<template>
  <div>
    <pedidos-filter @filter="filter"></pedidos-filter>
    <pedidos-table :loading="loading"></pedidos-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { ACTIONS, GETTERS } from '../store/types'
import PedidosTable from '../components/PedidosTable.vue'
import PedidosFilter from '../components/PedidosFilter.vue'

export default {
  name: 'PedidosReport',
  components: { PedidosTable, PedidosFilter },

  data: () => ({
    loading: false
  }),

  computed: {
    ...mapGetters({
      getterEstabelecimentos: GETTERS.ESTABELECIMENTOS,
      getterPedidos: GETTERS.PEDIDOS,
      getterInitialDate: GETTERS.INITIAL_DATE,
      getterFinalDate: GETTERS.FINAL_DATE,
    })
  },

  methods: {
    ...mapActions({
      actionFetchEstabelecimentos: ACTIONS.FETCH_ESTABELECIMENTOS,
      actionFetchPedidos: ACTIONS.FETCH_PEDIDOS
    }),
    filter(value) {
      this.loading = true

      const data_inicio = this.getterInitialDate
      const data_fim = this.getterFinalDate

      this.actionFetchPedidos({ ...value, data_inicio, data_fim })
        .finally(() => this.loading = false)
    }
  },

  mounted() {
    this.actionFetchEstabelecimentos()
  }
}
</script>

<style>

</style>